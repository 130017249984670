import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import useContentful from "../hooks/useContentful";
import Logo from "../Logo";
import PageTitle from './PageTitle';
import MotionContent from "../motion/MotionContent";

const query = `{
    motionListCollection(where: {title: "motion"}, limit: 1) {
        items {
            title
            motionItemsCollection {
                items {
                    title
                    client
                    previewVideoId
                    slug
                    filmGrabCollection {
                        items {
                            title
                            url_small: url(transform: { format: JPG_PROGRESSIVE, height: 300 })
                        }
                    }
                    sys{id}
                }
            }
        }
    }
}`

function Motion() {
    let { data } = useContentful(query);

    if (!data) return <motion.div className="motion page-loader"><Logo /></motion.div>;

    const pageTitle = data.motionListCollection.items[0].title;
    const motionItems = data.motionListCollection.items[0].motionItemsCollection.items;

    console.log(motionItems[0])

    return (
        <motion.div 
            className="motion-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <Helmet>
                <title>Mat+Kat | {pageTitle}</title>
            </Helmet>
            <Outlet className="outlet" />
            <PageTitle title={pageTitle}/>
            <div className="motion-wrapper">
                <MotionContent items={motionItems} />
            </div>
        </motion.div>
    )
}

export default Motion;