import { useRef, useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { motion, useInView, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const holder = {
    open: {
        opacity: 1
    },
    close: {
        opacity: 0,
        transition: {
            delay: 1.5
        }
    }
}

const text = {
    animate: {
        color: '#c2000b',
        scale: [ 0.9, 1 ]
    },
    hover: {
        color: '#c2000b'
    }
}

const grabContainer = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.3,
            duration: 0.5,
            staggerChildren: 0.5,
            delayChildren: 0.3
        }
    }
}

const FilmGrab = ({ src, alt }) => {
    const [imageHeight, setImageHeight] = useState('auto');

    useEffect(() => {
        const img = new Image();
        img.src = src;

        img.onload = () => {
            const { width, height } = img;
            if (height > width) {
                setImageHeight('16vh');
            } else {
                setImageHeight('9vh');
            }
        }
    }, [src]);

    return (
        <motion.img
            src={src}
            alt={alt}
            style={{ height: imageHeight, width: 'auto', display: 'block', padding: '0.25rem'}}
            />
    )
}

const MotionItem = ({item}) => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const videoRender = useInView(ref, {once: true})

    useEffect(() => {
        console.log("Element is in view: ", isInView)
    }, [isInView])

    const settings = {
        background: true,
        loop: true,
        responsive: true,
        muted: false,
        pip: false,
        controls: false
    }

    return (
        <div className="motion-item" id={item.id} ref={ref}>
            <div className="motion-item-content">
                <Link
                    className="project-link"
                    type="button"
                    to= {item.slug}
                >
                    <motion.div 
                        className="project-title"
                        variants={text}
                        whileHover="hover" 
                        whileTap="animate"
                        >
                        <h1>{item.client}</h1>
                        <h2>{item.title}</h2>
                    </motion.div>
                </Link>
                {videoRender && (
                    <Vimeo {...settings}
                        className= "motion-playr"
                        video= {item.previewVideoId}
                        width= "100%"
                        height= "100%"
                        quality= "360p"
                        paused= { !isInView }
                        />
                )}
                {videoRender && (
                    <motion.div
                        variants={holder}
                        initial="open"
                        animate="close"
                        className="motion-holder"
                        />
                )}
                {isInView && 
                    <motion.div
                        className="film-grabs"
                        variants={grabContainer}
                        initial="hidden"
                        animate="visible"
                        >
                        {item.filmGrabCollection.items.map((image, index) => (
                            <FilmGrab 
                                src={image.url_small} 
                                alt={image.title}
                                key={index}
                                />
                        ))}
                    </motion.div>
                }
            </div>
        </div>
    )
}

const MotionContent = (props) => {

    return (
        <>
        <div className="motion-list" id="list">
            {props.items.map((item, id) => (
                <MotionItem item={item} id={id} key={id} />
            ))}
        </div>
        </>
    )
}

export default MotionContent;